import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'; // Import axios
import banner from "../../assets/icons/banner.svg";
import toran from "../../assets/icons/toran.svg";
import ringArrow from "../../assets/icons/ringArrow.svg";

const Hero = () => {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Simple email validation function
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        setIsSubmitting(true); // Show loading state if needed

        // Send POST request using axios
        try {
            const response = await axios.post('https://backend.classmatetest.com/api/auth/onboard', {
                email: email, // Payload containing the email
            });

            if (response.status === 200) {
                toast.success('You have subscribed successfully!');
                setEmail(''); // Reset email input
            } else {
                toast.error('Failed to subscribe. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting email:', error);
            toast.error('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false); // Stop loading state
        }
    };

    return (
        <div className='flex flex-col w-full h-full lg:flex-row '>
            <div className='w-full lg:w-1/2 h-full flex-col flex justify-center lg:justify-start gap-[32px] '>
                <div className='lg:w-[90%] h-full flex-col flex justify-start gap-[2rem] items-start pt-[2.2rem] lg:pt-[4rem]'>
                    <div className='h-[107px] w-[120px] hidden lg:flex justify-start' >
                        <img src={toran} alt="toran" className='w-full h-full ' />
                    </div>
                    <div className='flex flex-col gap-[12px] text-center lg:text-left mx-auto lg:mx-0'>
                        <h1 className='text-[3rem] lg:text-[4.188rem] leading-[3rem] sm:leading-[4.5rem] pt-semibold'>
                            Website Launching Soon
                        </h1>
                        <p className='text-[1.1rem] text-[#667085]'>
                            Subscribe with email to get notified instantly
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} className='flex justify-center w-full gap-2 mx-auto dm:gap-3 lg:justify-start lg:mx-0'>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Enter Email'
                            className='w-full sm:!w-[353px] h-[44px] py-[0.625rem] px-[1.125rem] border border-[#98A2B3] text-[.8rem] pt-regular rounded-[100px]'
                            disabled={isSubmitting} // Disable input while submitting
                        />
                        <div className='relative'>
                            <button
                                type="submit"
                            className='Subscribe text-[1rem] text-white bg-[#2970FF] py-[0.625rem] px-[1rem] sm:px-[1.5rem] pt-medium rounded-[100px]'
                                disabled={isSubmitting} // Disable button while submitting
                            >
                                {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                            </button>
                            <img
                                src={ringArrow}
                                alt="ringArrow"
                                className='absolute flex h-[46px] w-[46px] md:h-[140px] md:w-[140px] top-[60px] sm:top-[70px] right-[20px] sm:right-[10px]'
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div className='flex items-center justify-center w-full h-full lg:w-1/2 bannerBg'>
                <img src={banner} alt="banner" className='object-fill' />
            </div>

        </div>
    );
};

export default Hero;
