import React from 'react'
import facebook from "../../assets/icons/Facebook.svg"
import instagram from "../../assets/icons/instagram.svg"
import linkedin from "../../assets/icons/linkedin.svg"
import youtube from "../../assets/icons/youtube.svg"
import twitter from "../../assets/icons/twitter.svg"

const Footer = () => {
  return (
    <div className='flex  justify-center sm:justify-between flex-wrap gap-y-3 gap-x-[3rem] py-[1rem] '>
        <span className='text-[1rem] text-[#667085]'>© Classtest 2024</span>
        <div className='flex gap-3'>
            <img src={facebook} alt="facebook" className='h-[24px] w-[24px] cursor-pointer'/>
            <img src={instagram} alt="instagram" className='h-[24px] w-[24px] cursor-pointer'/>
            <img src={twitter} alt="twitter" className='h-[24px] w-[24px] cursor-pointer'/>
            <img src={linkedin} alt="linkedin" className='h-[24px] w-[24px] cursor-pointer'/>
            <img src={youtube} alt="youtube" className='h-[24px] w-[24px] cursor-pointer'/>
        </div>
    </div>
  )
}

export default Footer