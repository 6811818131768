import React from 'react'
import LOGO from "../../../assets/icons/LOGO.svg"
import LOGOText from "../../../assets/icons/LOGOText.svg"

const Navbar = () => {
    return (
        <div className='w-full py-4 '>
            <div className='flex gap-2 items-center '>
                <img src={LOGO} alt="LOGO" />
                <img src={LOGOText} alt="LOGOText" />
            </div>
        </div>
    )
}

export default Navbar