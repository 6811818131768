import logo from './logo.svg';
import './App.css';
import Navbar from './components/Footer/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Footer from './components/Footer/Footer';
import orangeBlur from "./assets/icons/orangeBlur.svg"
import blueBlur from "./assets/icons/blueBlur.svg"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App flex justify-center items-center h-screen w-screen relative overflow-hidden">
      <ToastContainer />
      <img src={orangeBlur} alt="orangeBlur" className='absolute top-0 right-0 z-[-1]' />
      <img src={blueBlur} alt="blueBlur" className='absolute bottom-0 left-0 z-[-1]' />
      <div className='flex flex-col w-full h-full overflow-y-auto max-w-[1440px] px-[1rem] md:px-[2rem]'>
        <Navbar />
        <Hero />
        <Footer />
      </div>
    </div>
  );
}

export default App;
